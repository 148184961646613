import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ApiService } from "../../services/apiService";

const BlogPost = () => {
  const { blog_id } = useParams();
  const navigate = useNavigate();
  const [blogPost, setBlogPost] = useState(null);

  const fetchBlogPost = async () => {
    try {
      const response = await ApiService.getOneBlog(blog_id);
      const fetchedBlog = response.data.data;
      setBlogPost(fetchedBlog);

      // Construct new path based on the heading
      const headingPath = fetchedBlog.heading
        .toLowerCase()
        .replace(/\//g, "-") // Replace slashes with hyphens
        .replace(/[^a-z0-9\s-]/g, "") // Remove other special characters
        .replace(/\s+/g, "-")
        .trim();

      // Replace URL without reloading the page
      navigate(`/blog/${headingPath}`, { replace: true });
    } catch (error) {
      console.error("Failed to fetch the blog post", error);
    }
  };

  useEffect(() => {
    fetchBlogPost();
  }, [blog_id]);

  if (!blogPost) {
    return <p>Loading...</p>;
  }

  const anchorStyle = {
    color: "blue",
    textDecoration: "underline",
  };

  const styledContent = blogPost.content.replace(
    /<a /g,
    `<a style="${Object.keys(anchorStyle)
      .map((key) => `${key}: ${anchorStyle[key]};`)
      .join(" ")}" target="_blank"`
  );

  return (
    <div className="px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1 pt-2 pb-6 font-FiraSans">
      <h1 className="font-bold text-center text-4xl lg:text-2xl md:text-2xl sm:text-xl xs:text-xl">
        {blogPost.heading}
      </h1>
      <div className="mt-4">
        <p
          className="font-FiraSans"
          dangerouslySetInnerHTML={{ __html: styledContent }}
        />
      </div>
    </div>
  );
};

export default BlogPost;
